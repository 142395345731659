@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.tableWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border-radius: 1rem;
}

.card {
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
}

.rowEven {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: 1px solid #ecebeb;
//   font-size: 1.4rem;
  margin-bottom: 1rem;
//   &:hover {
//     -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
//     box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
//   }
}

.graphEntry {
  margin-bottom: 2rem;
}

.rowOdd {
  display: flex;
  flex-direction: column;
  background-color: rgb(244, 244, 244);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  border: 1px solid #ecebeb;
//   font-size: 1.4rem;
  margin-bottom: 1rem;
//   &:hover {
//     -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
//     box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
//   }
}

.rowCard {
  display: flex;
  margin-bottom: 0.6rem;
}

.rowCardTitle {
  opacity: 0.6;
  width: 12.5rem;
}

.rowCardValue {
  flex: 1;
  flex-wrap: wrap;
}
