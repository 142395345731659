@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.cardWrap {
  display: flex;
  padding: 1rem 2rem;
  background-color: white;
  flex-direction: column;
  border-radius: 1rem;

  border: 1px solid #ecebeb;
  align-items: flex-start;
  justify-content: space-between;
  min-height: 15rem;
  min-width: 35rem;
  cursor: pointer;
  &:hover {
    -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  }
  @include media-max($sdc-screen-mobile-landscape) {
    padding: 0.5rem 1rem;
  }
}

.buName {
  font-size: 2rem;
  // color: #000033;
  font-weight: 900;
  //   color: rgba(26, 90, 162, 1)
}

.current {
  font-size: 1.7rem;
  // color: #000033;
}

.taxRevenueWrap {
  display: flex;
}
.address {
  font-size: 1.7rem;
  // color: #000033;
}

.revenue {
  font-size: 1.7rem;
  // color: #000033;
}

.tax {
  font-size: 1.7rem;
  // color: #000033;
}
