@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.modalWrap {
  position: absolute;
  display: flex;
  width: 40%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 40rem;
  min-height: 50rem;
  border-radius: 2rem;
  position: relative;
  outline: none;
  @include media-max($sdc-screen-mobile-landscape) {
    min-width: 35rem;
    min-height: 50rem;
  }
}

.modalInner::-webkit-scrollbar {
  display: none;
}

.modalInner {
  display: flex;
  flex: 1;
  border-radius: 2rem;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  overflow: auto;
  padding-bottom: 10rem;
}

.inputWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  flex: 1;
  background-color: white;
  bottom: 0rem;
  width: 100%;
  right: 0rem;
  padding: 2rem;
  border-radius: 2rem;
}

.title {
  font-size: 2.5rem;
}
