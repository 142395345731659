@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.previewWrap {
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
}

.screen {
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;
  flex-direction: column;
}

.scrollView {
  overflow: "scroll";
  height: "100%";
  -webkit-overflow-scrolling: touch;
}

.invoiceDownloaded {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  white-space: nowrap;
}

.invoiceDownloadedLg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  white-space: nowrap;
}

.buttonWrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  margin-bottom: 5rem;
}
