@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.wrap {
  display: flex;
  background-color: #dbe2e6;
  border-radius: 2rem;
  padding: 0.2rem 1rem;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.title {
  margin-right: 1rem;
}

.closeIcon {
  width: 1rem;
  height: 1 rem;
  cursor: pointer;
}
