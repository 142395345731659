@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  min-height: 6rem;
  left: 0;
  border-bottom: 1px solid #ebebeb;
  right: 0;
  align-items: center;
  padding: 1rem 2rem;
  padding-right: 3rem;
  background-color: #fafafa;
  z-index: 2;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
}

.email {
  cursor: pointer;
}

.disableTextSelect {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.menuIcon {
  cursor: pointer;
}

.chevronUp {
  transform: rotate(180deg);
}

.chevronDown {
}

.rowAlign {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
