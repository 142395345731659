@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.tableWrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border-radius: 1rem;

  ::-webkit-scrollbar {
    height: 8px;
    width: 0px;
    border: 1px solid #d5d5d5;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background-color: #000;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
  }

  ::-webkit-scrollbar-thumb {
    background: #888 !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }
}

.buName {
  white-space: nowrap;
}

.buWrapHidden {
  width: 0;
  overflow: hidden;
}

.buNameTitle {
  white-space: nowrap;
  font-size: 1.5rem;
  color: rgb(181, 181, 181);
  cursor: pointer;
}

.tableContentWrap {
  display: flex;
  overflow: scroll;
  flex: 1;
  width: 100%;
}

.buPart {
  display: flex;
  flex-direction: column;
}

.headerTitle {
  font-size: 1.5rem;
  color: rgb(181, 181, 181);
  white-space: nowrap;
}

.odd {
  background-color: rgb(244, 244, 244);
}

.headerAndContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tableContent {
  display: flex;
  width: 100%;
  flex: 1;
  overflow: scroll;
}

.headerCell {
  display: flex;
  padding: 1rem;
  width: 100%;
  flex: 1;
  min-width: 17rem;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: rgb(244, 244, 244);
}

.cell {
  display: flex;
  padding: 1rem;
  width: 100%;
  flex: 1;
  min-width: 13rem;
}

.buCell {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  flex: 1;
}

.revenueCell {
  display: flex;
  padding: 1rem;
  width: 100%;
  flex: 1;
  min-width: 17rem;
  position: relative;
  cursor: pointer;
}

.tableHeader {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(244, 244, 244);
}

.tableRow {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.tooltip {
  position: absolute;
  z-index: 2;
  padding: 1rem;
  right: 1rem;
  background-color: white;
  border-radius: 1rem;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
}

.revenueTooltip {
  white-space: nowrap;
}
