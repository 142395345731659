@import "../../../../sass/variables.scss";
@import "../../../../sass/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 0rem 2rem;
  @include media-max($sdc-screen-mobile-landscape) {
    padding: 0rem 1rem;
  }
}

.journalLink {
  color: rgb(51, 51, 51);
  text-decoration: underline;
}

.closeIcon {
  cursor: pointer;
  margin-right: 1rem;
}

.title {
  font-size: 4rem;
  white-space: nowrap;
}

.titleMobile {
  font-size: 3.2rem;
  margin-bottom: 0.8rem;
}

.link {
  color: $text-color;
  text-decoration: none;
  &:hover {
    opacity: 0.1;
  }
}
.card {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #fff;
  padding: 2rem 4rem;
  border-radius: 1rem;
  border: 1px solid #ecebeb;
  font-size: 1.6rem;
  @include media-max($sdc-screen-mobile-landscape) {
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
  }
}

.listCard {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-top: 1.2rem;
  border: 1px solid #ecebeb;
  font-size: 1.4rem;
  &:hover {
    -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  }
}

.rowCard {
  display: flex;
  margin-bottom: 0.6rem;
}

.rowCardTitle {
  opacity: 0.6;
  width: 12.5rem;
}

.rowCardValue {
  flex: 1;
  flex-wrap: wrap;
}

.maxHeight {
  max-height: 40rem;
}

.cardContent {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.gridHalves {
  display: grid;
  grid-template-columns: 3fr 3fr;
  margin-top: 2rem;
  overflow: scroll;

  @include media-max($sdc-screen-mobile-landscape) {
    grid-template-columns: none;
    grid-gap: 1rem;
  }
}

.clickable {
  text-decoration: underline;
  width: 100%;
  cursor: pointer;
}

.marginTop {
  margin-top: 10rem;
}

.itemTitle {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(111, 111, 111);
}

.itemSubtitle {
  color: rgb(111, 111, 111);
}

.section {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  margin-bottom: 1rem;
  flex: 1;
  @include media-max($sdc-screen-mobile-landscape) {
    padding: 0rem 1rem;
  }
}

.sectionMax {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  // max-height: 30rem;
  flex: 1;
  @include media-max($sdc-screen-mobile-landscape) {
    padding: 0rem 1rem;
  }
}

.itemsWrap {
  @include media-max($sdc-screen-tablet-landscape) {
    margin-top: 10rem;
  }
}

.marginTop {
  margin-top: 2rem;
}

.marginBottom {
}

.sectionTitle {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(111, 111, 111);

  margin-bottom: 2rem;
}

.itemsHeader {
  display: flex;
  flex: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.headerItem {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.headerItemLg {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
}

.itemValueLg {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;
}

.itemRow {
  display: flex;
  flex: 1;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.itemValue {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.alignLeft {
  align-items: flex-start;
}

.alignRight {
  align-items: flex-end;
}

.headerTitle {
}

.borderTop {
  border-top: 1px solid rgb(199, 199, 199);
}

.borderBottom {
  border-bottom: 1px solid rgb(199, 199, 199);
}

.noItemsDiv {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.actions {
  display: flex;
  flex-direction: column;
}

.sectionHeader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.editButton {
  cursor: pointer;
}

.deleteIcon {
  margin-right: 1rem;
  cursor: pointer;
}

.configRow {
  display: flex;
  flex: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.configLabel {
  display: flex;
  flex: 1;
}

.configValue {
  display: flex;
  flex: 2;
  align-items: center;
}

.touchable {
  cursor: pointer;
  &:hover {
    transition: all 0.2s;
    background-color: #dbe2e6;
  }
}

.actionsWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.textBold {
  font-weight: 700;
  color: rgb(111, 111, 111);
}

.titleWrap {
  display: flex;
  margin-left: 2rem;
}
.hintText {
  color: red;
}

.odd {
  background-color: rgb(249, 248, 248);
}

.even {
  background-color: white;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 4rem;
}

.cellMd {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
}

.cell {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}

.cellLg {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
}

.ofline {
  display: flex;
  border-radius: 100%;
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  background-color: rgb(210, 56, 56);
}

.online {
  display: flex;
  border-radius: 100%;
  width: 1rem;
  margin-right: 1rem;
  height: 1rem;
  background-color: rgb(60, 144, 60);
}
