@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.listContainer {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  flex: 1;
}
