@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";
.title {
  font-size: 4rem;
}

.titleMobile {
  font-size: 2.2rem;
  margin-bottom: 0.8rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 0rem 2rem;
}

.filterIcon {
  height: 2.5rem;
  cursor: pointer;
}

.filterWrap {
}

.contentMobile {
  flex: 1;
}

.checkBoxWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 2rem;
}

.checkBox {
  cursor: pointer;
  color: red($color: #000000);
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1rem;
  background-color: #eee;
}

.itemWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 4rem;
  margin-bottom: 1rem;
  background-color: white;
  flex: 1;
  width: 100%;
  border-radius: 1rem;
  flex-direction: row;
  cursor: pointer;
}

.aTag {
  text-decoration: none;
  color: rgb(51, 51, 51);
}

.listWrapper {
  // margin-top: 2rem;
}

.listWrapperMobile {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.itemTitle {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(111, 111, 111);
}

.itemSubtitle {
  color: rgb(111, 111, 111);
}

.clickable {
  text-decoration: underline;
}

.itemInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.topPart {
  display: grid;
  grid-template-columns: 3fr 3fr;
  width: 100%;
  @include media-max($sdc-screen-mobile-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
  }
}

.flexEnd {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  // @include media-max($sdc-screen-mobile-landscape) {
  //   flex-direction: column;
  //   align-items: flex-start !important;
  //   justify-content: flex-start !important;
  // }
}

.filterWrap {
  position: absolute;
  display: flex;
  right: 0%;
  width: 30%;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 1rem;
  background-color: #fafafa;
  z-index: 99;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  transition: 0.5s;

  @include media-max($sdc-screen-mobile-landscape) {
    width: 80%;
  }
}

.filterWrapClosed {
  position: absolute;
  display: flex;
  right: -5%;
  width: 0%;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 0;
  background-color: white;
  z-index: 99;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  transition: 0.5s;
}

.filterHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filters {
  font-weight: bold;
}

.filterContent {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
}

.inputWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
}

.closeIconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 4rem;
  margin-right: 1rem;
  height: 4rem;
  border-radius: 100%;
  &:hover {
    transition: all 0.2s;
    background-color: #dbe2e6;
  }
}

.closeIcon {
  cursor: pointer;
}

.filterIconWrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbe2e6;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
  @include media-max($sdc-screen-mobile-landscape) {
    margin-bottom: 1rem;
  }
  &:hover {
    transition: all 0.2s;
    background-color: #dbe2e6;
  }
}

.filterIcon {
  cursor: pointer;
}

.filterIconActive {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  border: 1px solid #dbe2e6;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background-color: #dbe2e6;
  margin-right: 1rem;
  @include media-max($sdc-screen-mobile-landscape) {
    margin-bottom: 1rem;
  }
}

.inputWrapper {
  display: flex;
  margin-top: 1rem;
  width: 20em;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @include media-max($sdc-screen-mobile-landscape) {
    width: 17em;
  }
}

.online {
  display: flex;
  border-radius: 100%;
  width: 1rem;
  margin-right: 1rem;
  height: 1rem;
  background-color: rgb(60, 144, 60);
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ofline {
  display: flex;
  border-radius: 100%;
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  background-color: rgb(210, 56, 56);
}

.odd {
  background-color: rgb(249, 248, 248);
}

.even {
  background-color: white;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 4rem;
}

.cellMd {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
}

.cell {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}

.cellLg {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-top: 1.2rem;
  border: 1px solid #ecebeb;
  font-size: 1.4rem;
  &:hover {
    -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  }
}

.downloadHeader {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
}

.rowCard {
  display: flex;
  margin-bottom: 0.6rem;
}

.rowCardTitle {
  opacity: 0.6;
  width: 12.5rem;
}

.rowCardValue {
  flex: 1;
  flex-wrap: wrap;
}

.downloadBtnWrap {
  margin-right: 1rem;
}
