@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.paginationContainer {
  display: flex;
}

.paginationItem {
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  font-size: 1.4rem;
  margin: 0 0.2rem;
  width: 3.2rem;
  cursor: pointer;
  justify-content: center;
}

.selected {
  background-color: #074e6e28;
  border-radius: 5px;
}

.arrow {
  display: flex;
  background-color: #dbe2e6;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  width: 3rem;
  align-items: center;
  justify-content: center;
}

.disabled {
  pointer-events: none;
}

.icon {
  padding: 0.4rem;
}

.chevronLeft {
  transform: rotate(90deg);
  color: white;
}

.chevronRight {
  transform: rotate(-90deg);
}
