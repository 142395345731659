@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

@include media-max($sdc-screen-mobile-landscape) {
  min-width: 35rem;
  min-height: 50rem;
}

.graphDesktop {
  display: flex;
  width: 100%;
  height: 37rem;
  @include media-max($sdc-screen-mobile-landscape) {
    display: none;
  }
}

.graphSm {
  display: none;
  @include media-max($sdc-screen-mobile-landscape) {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 30rem;
  }
}
