@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.wrap {
  border: 1px solid #dbe2e6;
  cursor: pointer;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 4rem;
  width: 12rem;
  height: 4rem;
  &:hover {
    transition: all 0.2s;
    background-color: #dbe2e6;
    overflow: hidden;
  }
}

.dropdownWrap {
  position: relative;
}

.menu {
  position: absolute;
  top: 4.5rem;
  right: 0;
  z-index: 10;
  background-color: white;
  border-radius: 1rem;
  width: 15rem;
  border: 1px solid #dbe2e6;
}

.disabled {
  background-color: #dbe2e6;
  cursor: disabled;
}

.buttonText {
  font-size: 1.5rem;
}

.chevronUp {
  transform: rotate(180deg);
}

.item {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 2rem;
  border: 1px solid transparent;
  cursor: pointer;
  overflow: hidden;
  &:hover {
    transition: all 0.2s;
    background-color: #dbe2e6;
    overflow: hidden !important;
  }
}

.borderTop {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.borderBottom {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
