@import "../../../../sass/variables.scss";
@import "../../../../sass/mixins.scss";

.title {
  font-size: 4rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 150rem;
  margin-top: 2rem;
  padding: 0rem 2rem;
  overflow-y: hidden;
}

.aTag {
  text-decoration: none;
  color: rgb(51, 51, 51);
}

.offline {
  display: flex;
  border-radius: 100%;
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  background-color: rgb(210, 56, 56);
}

.online {
  display: flex;
  border-radius: 100%;
  width: 1rem;
  margin-right: 1rem;
  height: 1rem;
  background-color: rgb(60, 144, 60);
}

.itemWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 4rem;
  margin-bottom: 1rem;
  background-color: white;
  flex: 1;
  width: 100%;
  border-radius: 1rem;
  flex-direction: row;
  cursor: pointer;
}

.filterIconActive {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  background-color: #dbe2e6;
  margin-right: 1rem;
  @include media-max($sdc-screen-mobile-landscape) {
    margin-bottom: 1rem;
  }
}

.filterIcon {
  height: 2.5rem;
  cursor: pointer;
}

.filterIconWrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
  @include media-max($sdc-screen-mobile-landscape) {
    margin-bottom: 1rem;
  }
}

.filterWrap {
  position: absolute;
  display: flex;
  right: 0%;
  width: 30%;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 1rem;
  background-color: #fafafa;
  z-index: 99;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  transition: 0.5s;

  @include media-max($sdc-screen-mobile-landscape) {
    width: 80%;
  }
}

.filters {
  font-weight: bold;
}

.filterContent {
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
}

.closeIconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 4rem;
  margin-right: 1rem;
  height: 4rem;
  border-radius: 100%;
  &:hover {
    transition: all 0.2s;
    background-color: #dbe2e6;
  }
}

.filterHeader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filterWrapClosed {
  position: absolute;
  display: flex;
  right: -5%;
  width: 0%;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 0rem;
  background-color: white;
  z-index: 99;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  transition: 0.5s;
}

.onlineWrap {
  display: flex;
  align-items: center;
}

.listWrapper {
  margin-top: 2rem;
}

.itemTitle {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(111, 111, 111);
}

.itemSubtitle {
  color: rgb(111, 111, 111);
}

.itemInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.topPart {
  display: grid;
  grid-template-columns: 3fr 3fr;
  width: 100%;
  @include media-max($sdc-screen-mobile-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
  }
}

.flexEnd {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  @include media-max($sdc-screen-mobile-landscape) {
    justify-content: flex-start;
  }
}

.inputWrapper {
  display: flex;
  width: 20em;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.tabHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 150rem;
  align-items: center;
  justify-content: flex-start;
}
