@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}

.gridHalves {
  display: grid;
  grid-template-columns: 5fr 3fr;
  width: 100%;
  height: 100%;
  @include media-max($sdc-screen-tablet-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
  }
}

.contentWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  flex-direction: column;
  padding: 10rem;
  @include media-max($sdc-screen-mobile-landscape) {
    padding: 1rem;
  }
}

.title {
  font-size: 4rem;
  font-weight: 700;
}

.inputFullWidthWrapper {
  display: flex;
  width: 40%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @include media-max($sdc-screen-mobile-landscape) {
    width: 100%;
  }
}

.alignRowJustifyBetween {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
}

.formWrapper {
  margin-top: 5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include media-max($sdc-screen-tablet-landscape) {
    // display: none;
    width: 80%;
  }

  @include media-max($sdc-screen-tablet-portrait) {
    // display: none;
    width: 100%;
  }
}

.continueButtonWrap {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
