@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.title {
  font-size: 4rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 150rem;
  padding: 0rem 2rem;
  overflow-y: hidden;
}

.itemWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 4rem;
  margin-bottom: 1rem;
  background-color: white;
  flex: 1;
  width: 100%;
  border-radius: 1rem;
  flex-direction: row;
  cursor: pointer;
}

.listWrapper {
  margin-top: 2rem;
}

.itemTitle {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(111, 111, 111);
}

.itemSubtitle {
  color: rgb(111, 111, 111);
}

.clickable {
  text-decoration: underline;
}

.itemInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.topPart {
  display: grid;
  grid-template-columns: 3fr 3fr;
  width: 100%;
  @include media-max($sdc-screen-mobile-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
  }
}

.flexEnd {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  @include media-max($sdc-screen-mobile-landscape) {
    justify-content: flex-start;
  }
}

.inputWrapper {
  display: flex;
  width: 20em;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
