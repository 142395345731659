@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";
.title {
  font-size: 2.5rem;
}

.titleMobile {
  font-size: 2.5rem;
  margin-bottom: 0.8rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 0rem 2rem;
  @include media-max($sdc-screen-mobile-landscape) {
    padding: 0rem 1rem;
  }
}

.contentMobile {
  flex: 1;
}

.itemWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 4rem;
  margin-bottom: 1rem;
  background-color: white;
  flex: 1;
  width: 100%;
  border-radius: 1rem;
  flex-direction: row;
  cursor: pointer;
}

.aTag {
  text-decoration: none;
  color: rgb(51, 51, 51);
}

.listWrapper {
  // margin-top: 2rem;
}

.listWrapperMobile {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.itemTitle {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(111, 111, 111);
}

.itemSubtitle {
  color: rgb(111, 111, 111);
}

.clickable {
  text-decoration: underline;
}

.itemInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.topPart {
  display: grid;
  grid-template-columns: 3fr 3fr;
  width: 100%;
  padding: 1rem 0rem;
  @include media-max($sdc-screen-mobile-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
  }
}

.flexEnd {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  @include media-max($sdc-screen-mobile-landscape) {
    justify-content: flex-start;
  }
}

.inputWrapper {
  display: flex;
  width: 20em;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.online {
  display: flex;
  border-radius: 100%;
  width: 1rem;
  margin-right: 1rem;
  height: 1rem;
  background-color: rgb(60, 144, 60);
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ofline {
  display: flex;
  border-radius: 100%;
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  background-color: rgb(210, 56, 56);
}

.odd {
  background-color: rgb(249, 248, 248);
}

.even {
  background-color: white;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 4rem;
}

.cellMd {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
}

.cell {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}

.cellLg {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
}

.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  margin-top: 1.2rem;
  border: 1px solid #ecebeb;
  font-size: 1.4rem;
  &:hover {
    -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  }
}

.rowCard {
  display: flex;
  margin-bottom: 0.6rem;
}

.rowCardTitle {
  opacity: 0.6;
  width: 12.5rem;
}

.rowCardValue {
  flex: 1;
  flex-wrap: wrap;
}
