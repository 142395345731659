@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.title {
  font-size: 4rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 150rem;
  padding: 0rem 2rem;
  overflow-y: hidden;
}

.titleWrap {
  display: flex;
  margin-left: 2rem;
}

.textBold {
  font-weight: 700;
  color: rgb(111, 111, 111);
}

.title {
  font-size: 4rem;
}

.gridHalves {
  display: grid;
  grid-template-columns: 3fr 3fr;
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  @include media-max($sdc-screen-tablet-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
  }
}

.editButton {
  cursor: pointer;
}

.sectionMax {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  max-height: 30rem;
  flex: 1;
}

.maxHeight {
  max-height: 40rem;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  margin-bottom: 1rem;
  flex: 1;
}

.marginTop {
  margin-top: 2rem;
}

.marginBottom {
  margin-bottom: 10rem;
}

.sectionHeader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.sectionTitle {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(111, 111, 111);
  margin-bottom: 2rem;
}

.actionsWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteButton {
  margin-right: 1rem;
  cursor: pointer;
}

.configRow {
  display: flex;
  flex: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.configLabel {
  display: flex;
  width: 20%;
}

.aTag {
  text-decoration: none;
  color: rgb(51, 51, 51);
}

a,
li {
  text-decoration: none !important;
}

.configValue {
  display: flex;
}

.card {
  display: flex;
  height: 100%;
  flex-direction: column;
  background-color: #fff;
  padding: 2rem 4rem;
  border-radius: 1rem;
  border: 1px solid #ecebeb;
  font-size: 1.6rem;
  @include media-max($sdc-screen-mobile-landscape) {
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
  }
}

.closeIcon {
  cursor: pointer;
  margin-right: 1rem;
}
