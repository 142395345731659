@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.container {
  display: flex;
  flex: 1;
}

.gridHalves {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  @include media-max($sdc-screen-full-hd) {
    grid-template-columns: 3fr 2fr;
  }

  @include media-max(560px) {
    display: flex;
    flex-direction: column;
  }
  @include media-max($sdc-screen-mobile-landscape) {
    display: none;
  }
}

.contentPart {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 6rem;
  @include media-max($sdc-screen-full-hd) {
    padding: 4rem;
  }
}

.mobileContentWrap {
  display: none;
  @include media-max($sdc-screen-mobile-landscape) {
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    flex-direction: column;
  }
}

.bgPrimary {
  background-color: #151751;
}

.image {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

.inputWrapper {
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @include media-max($sdc-screen-mobile-landscape) {
    width: 100%;
  }
}

.formWrap {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  width: 70%;
  @include media-max($sdc-screen-mobile-landscape) {
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.signInTitle {
  font-size: 4rem;
  margin-bottom: 3rem;
  font-weight: 700;
}

.forgotPassword {
  text-decoration: underline;
  margin-bottom: 2rem;
  cursor: pointer;
  &:hover {
    color: #a6abd5;
  }
}

.marginBottom {
  margin-bottom: 2rem;
}

.fullWidth {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
