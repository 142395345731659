@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.listWrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;
}

.itemWrap {
  display: flex;
  padding: 0.5rem;
}

.inputWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1rem;
}

.dateInput {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  height: 50px;
  background: #ffffff;
  border-radius: 15px;
  outline: none;
  font-size: 14px;
  padding: 0 16px;
  width: 100%;
  border: 1px solid #dbe2e6;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  &:focus {
    border: 1px solid rgba(26, 90, 162, 1);
  }
}
.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 7rem;
  width: 100%;
  padding-bottom: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
