@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.sideBar {
  height: 100%;
  width: 25rem;
  background-color: #fafafa;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  overflow: hidden;
  padding: 2rem;
  transition: 0.5s;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
}

.sideBarClosed {
  height: 100%;
  width: 25rem;
  background-color: #fafafa;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  padding: 2rem;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  overflow: hidden;
  transition: 0.5s;
  @include media-max($sdc-screen-tablet-landscape) {
    height: 100%;
    width: 0;
    background-color: #fafafa;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 5;
    overflow: hidden;
    padding: 0rem !important;
    transition: 0.5s;
  }
}

.logoWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}

.items {
  display: flex;
  flex: 1;
  margin-top: 4rem;
  flex-direction: column;
}

.logo {
  max-width: 6rem;
}

.lpfr {
  font-size: 2rem;
  margin-left: 1rem;
  font-weight: 800;
}
.item {
  display: flex;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex: 1;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
}

.itemSelected {
  display: flex;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #e6effc;
  flex: 1;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 1rem;
  cursor: pointer;
  position: relative;
}

.itemName {
  font-size: 1.7rem;
  line-height: 1.7rem;
  padding-left: 1rem;
  border-left: 3px solid transparent;
  white-space: nowrap;
}

.itemNameSelected {
  color: rgba(26, 90, 162, 1);
  font-size: 1.7rem;
  line-height: 1.7rem;
  padding-left: 1rem;
  border-left: 3px solid rgba(26, 90, 162, 1);
  white-space: nowrap;
}

.borderLeft {
  position: absolute;
  left: 0%;
  width: 2px;
  background-color: red;
  // border-left: 2px solid rgba(26, 90, 162, 1);
}

.borderLeftTransparent {
  position: absolute;
  left: 0%;
  width: 2px;
  background-color: red;
  border-left: 2px solid transparent;
}

.disableTextSelect {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
