@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.modalWrap {
  position: absolute;
  display: flex;
  width: 50%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 40rem;
  min-height: 50rem;
  border-radius: 2rem;
  position: relative;
  outline: none;
  @include media-max($sdc-screen-mobile-landscape) {
    min-width: 35rem;
    min-height: 50rem;
  }
}

.dateHalfInputWrap {
  width: 50%;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  margin-top: 7rem;
}

.marginTop {
  margin-top: 2rem;
}

.singleDayRevenue {
  padding-bottom: 1rem;
  padding-top: 1rem;
  border-bottom: 1px solid #dbe2e6;
  border-top: 1px solid #dbe2e6;
}

.datePickersWrap {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.intervalRevenueReport {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  border-bottom: 1px solid #dbe2e6;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 1rem;
}

.closeIconWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 4rem;
  margin-right: 1rem;
  height: 4rem;
  border-radius: 100%;
  &:hover {
    transition: all 0.2s;
    background-color: #dbe2e6;
  }
}

.title {
  font-size: 2rem;
}

.inputWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1 rem;
}

.inputWrapperSm {
  display: flex;
  width: 45%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
  @include media-max($sdc-screen-mobile-landscape) {
    width: 48%;
  }
}

.revenueWrap {
  display: flex;
  flex-direction: column;
}

.revenueWrapSm {
  display: flex;
  flex-direction: column;
}

.dateInput {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  height: 50px;
  background: #ffffff;
  border-radius: 15px;
  outline: none;
  font-size: 14px;
  padding: 0 16px;
  width: 100%;
  border: 1px solid #dbe2e6;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  &:focus {
    border: 1px solid rgba(26, 90, 162, 1);
  }
}

.closeIcon {
  cursor: pointer;
}

.modalInner::-webkit-scrollbar {
  display: none;
}

.modalInner {
  display: flex;
  flex: 1;
  border-radius: 2rem;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  padding-bottom: 10rem;
}

.content {
  padding: 0rem 1rem;
}
