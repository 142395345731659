.pulse {
    width: 15rem;
    height: 15rem;
    animation: pulse 1s infinite;
    margin: 0 auto;
    display: table;
    animation-direction: alternate;
    -webkit-animation-name: pulse;
    animation-name: pulse;
  }
  
  .pulseFrame {
    background: rgb(19, 58, 102);
    background: radial-gradient(rgba(26, 90, 162, 1) 20%, rgb(8, 24, 43) 80%);
    width: 100vw;
    height: 100vh;
    padding-top: 20vh;
  }
  
  @-webkit-keyframes pulse {
    0% {
      -webkit-transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.12);
    }
    100% {
      -webkit-transform: scale(1);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.12);
    }
    100% {
      transform: scale(1);
    }
  }
  