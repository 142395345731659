.customField {
  position: relative;
  width: 100%;
  font-size: 16px;
}

.input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  height: 50px;
  background: #ffffff;
  border-radius: 15px;
  outline: none;
  font-size: 14px;
  padding: 0 16px;
  width: 100%;
  border: 1px solid #dbe2e6;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  &:focus{
    border: 1px solid rgba(26, 90, 162, 1);
  }
}

.placeholder {
  position: absolute;
  left: 13px;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 100%;
  color: #92929d;
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
  padding: 0 3px;
}
