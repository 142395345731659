@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  padding: 0rem 2rem;
  flex: 1;
}

.inputWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1 rem;
}

.graphWrap {
  display: flex;
  flex: 1;
  width: 100%;
  border: 1px solid #dbe2e6;
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
  @include media-max($sdc-screen-mobile-landscape) {
    display: none;
  }
}

.graphSm {
  display: none;
  border: 1px solid #dbe2e6;
  padding: 1rem;
  background-color: white;
  border-radius: 1rem;
  @include media-max($sdc-screen-mobile-landscape) {
    overflow: hidden;
    display: flex;
    width: 100%;
    height: 30rem;
  }
}

.marginTop {
  margin-top: 1rem;
}

.graphs {
  display: flex;
  margin-top: 5rem;
  flex-direction: column;
}

.customTooltip {
  display: flex;
  background-color: white;
  flex-direction: column;
  padding: 1rem 2rem;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.17);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.17);
  border-radius: 1rem;
}

.alignCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  @include media-max($sdc-screen-mobile-landscape) {
    display: none;
  }
}

.alignCenterSm {
  display: none;
  @include media-max($sdc-screen-mobile-landscape) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dateInput {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  height: 50px;
  background: #ffffff;
  border-radius: 15px;
  outline: none;
  font-size: 14px;
  padding: 0 16px;
  width: 100%;
  border: 1px solid #dbe2e6;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  &:focus {
    border: 1px solid rgba(26, 90, 162, 1);
  }
}

.inputWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1 rem;
}

.inputWrapperSm {
  display: flex;
  width: 45%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 1rem;
  @include media-max($sdc-screen-mobile-landscape) {
    width: 100%;
  }
}

.title {
  font-size: 2rem;
}
.titleLg {
  font-size: 2.5rem;
}
