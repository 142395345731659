@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.title {
  font-size: 4rem;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 150rem;
  padding: 2rem 4rem;
  overflow-y: hidden;
}

.titleWrap {
  padding: 2rem;
}

.sectionHeader {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.sectionTitle {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(111, 111, 111);
  margin-bottom: 2rem;
}

.textBold {
  font-weight: 700;
  color: rgb(111, 111, 111);
}

.title {
  font-size: 4rem;
}

.gridHalves {
  display: grid;
  grid-template-columns: 3fr 3fr;
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  @include media-max($sdc-screen-tablet-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
  }
}

.editButton {
  cursor: pointer;
}

.sectionMax {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  flex: 1;
}

.maxHeight {
//   max-height: 40rem;
}

.clickable {
  text-decoration: underline;
  cursor: pointer;
}

.section {
  display: flex;
  flex-direction: column;
  padding: 1rem 3rem;
  margin-bottom: 1rem;
  flex: 1;
}

.tableHeader {
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.headerItem {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.card {
  display: flex;
  background-color: white;
  padding: 2rem 4rem;
  margin-bottom: 1rem;
  border-radius: 1rem;
  flex-direction: column;
  height: 100%;
//   max-height: 40rem;
  overflow-y: scroll;
}

.cardContent {
  display: flex;
  flex-direction: column;
}

.tableRow {
  display: flex;
  flex: 1;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tableCell {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.borderTop {
  border-top: 1px solid rgb(199, 199, 199);
}

.borderBottom {
  border-bottom: 1px solid rgb(199, 199, 199);
}
