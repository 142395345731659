@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.buttonWrap {
  background-color: #3c3e4e;
  border-radius: 1rem;
  width: 15rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #a6abd5;
  }
}

.buttonWrapLg {
  background-color: #3c3e4e;
  border-radius: 1rem;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #a6abd5;
  }
}

.buttonDisabled {
  background-color: #a6abd5;
}

.buttonName {
  color: white;
}

.large {
  width: 45rem;
  height: 15rem;
  border-radius: 3rem;
}

.largeFont {
  font-size: 4rem;
}
