$text-color: #1a2229;
$color-primary: #4164e3;

//shadows
$card-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.01);
$shadow-01: 0 4px 8px -2px rgba(0, 0, 0, 0.1);


$sdc-screen-full-hd: 120rem; // 1920px
$sdc-screen-laptop-lg: 95rem; //
$sdc-screen-laptop: 80rem; // 1280px
$sdc-screen-laptop-md: 75rem; // 1200px
$sdc-screen-laptop-sm: 64rem; // 1024px
$sdc-screen-tablet-landscape: 61.93rem; // 991px
$sdc-screen-tablet-portrait: 48rem; // 768px
$sdc-screen-mobile-landscape: 46.25rem; // 740px
$sdc-screen-mobile-portrait-lg: 30rem; // 480px
$sdc-screen-mobile-portrait: 23.4375rem; // 375px
$sdc-screen-mobile-sm-portrait: 20rem; // 320px
