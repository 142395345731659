@mixin respond($breakpoint) {
  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } // 112.5em == 1800px
  }
}

@mixin media-max($media) {
  @media only screen and (max-width: $media) {
    @content;
  }
}
