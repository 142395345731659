@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.modalWrap {
  position: absolute;
  display: flex;
  width: 60%;
  height: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 40rem;
  outline: none;
  min-height: 50rem;
  @include media-max($sdc-screen-mobile-landscape) {
    min-width: 35rem;
    min-height: 50rem;
  }
}

.modalInner {
  display: flex;
  flex: 1;
  border-radius: 2rem;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  padding: 2rem;
  overflow: auto;
  position: relative;
}
