@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0rem 0rem;
  overflow-y: hidden;
  flex: 1;
  position: relative;
}

.headerTitle {
  font-size: 1.5rem;
  color: rgb(181, 181, 181);
}

.header {
  display: flex;
  padding: 1rem 4rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  background-color: rgb(244, 244, 244);
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex: 1;
}

.footer {
  display: flex;
  padding: 1rem 4rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: rgb(244, 244, 244);
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.footerTransparent {
  background-color: transparent;
  align-items: flex-end;
  margin-top: auto;
}

.cell {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
}

.checkboxWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 2rem;
}

.cellLg {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
}

.downIcon {
  margin-left: 1rem;
}

.upIcon {
  transform: rotate(180deg);
  margin-left: 1rem;
}

.sortable {
  cursor: pointer;
}

.cellMd {
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: flex-start;
}

.odd {
  background-color: rgb(249, 248, 248);
}

.even {
  background-color: white;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 4rem;
}

.rows {
  max-height: 65vh;
  overflow: auto;
  display: flex;
  flex: 1;
  overflow: scroll;
  flex-direction: column;
}

.disableScroll {
  overflow: hidden !important;
}

.minHeight {
  min-height: 65vh;
}

.minHeightSm {
  min-height: calc(100vh - 23rem);
}

.loader {
  display: flex;
  flex: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.rowsMobileSm {
  overflow: auto;
  display: flex;
  overflow: scroll;
  flex-direction: column;
}

.rowsMobile {
  overflow: auto;
  display: flex;
  height: calc(100vh - 23rem);
  overflow: scroll;
  flex-direction: column;
}

.ofline {
  display: flex;
  border-radius: 100%;
  margin-right: 1rem;
  width: 1rem;
  height: 1rem;
  background-color: rgb(210, 56, 56);
}

.online {
  display: flex;
  border-radius: 100%;
  width: 1rem;
  margin-right: 1rem;
  height: 1rem;
  background-color: rgb(60, 144, 60);
}

.sortActive {
  color: rgba(26, 90, 162, 1) !important;
}
