@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.listWrap {
  display: flex;
  flex-direction: column;
}

.invoiceItem {
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem;
}

.border {
  border-bottom: 1px solid lightgray;
}

.groupWrap {
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  border-radius: 1rem;
  margin-bottom: 2rem;
  padding: 1rem;
}
