@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  background-color: #fafafa;
  margin-top: 6rem;
  position: relative;
  overflow-y: hidden;
  transition: 0.5s;
  margin-left: 25rem;
  @include media-max($sdc-screen-tablet-landscape) {
    margin-left: 0rem;
  }
}

.containerSideBarOpen {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  background-color: #fafafa;
  margin-top: 6rem;
  position: relative;
  overflow-y: hidden;
  transition: 0.5s;
  margin-left: 25rem;
  @include media-max($sdc-screen-tablet-landscape) {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    background-color: #fafafa;
    margin-top: 6rem;
    position: relative;
    margin-left: 25rem;
    overflow-y: hidden;
    transition: 0.5s;
  }
}

.childrenWrap {
  background-color: aqua;
}

.accountSettings {
  display: flex;
  position: absolute;
  top: 1rem;
  right: 2rem;
  flex: 1;
  padding: 2rem 4rem;
  flex-direction: column;
  border-radius: 1rem;
  background-color: white;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.17);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.17);
  align-items: center;
  justify-content: center;
  max-width: 50rem;
  z-index: 10;
}

.avatar {
  display: flex;
  width: 8rem;
  height: 8rem;
  border-radius: 100%;
  background-color: #ba4345;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.avatarInner {
  color: white;
  font-size: 5rem;
  line-height: 2rem;
}

.hidden {
  display: none;
}

.fullNameWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.fullName {
  font-size: 2rem;
}

.email {
  color: rgb(84, 84, 84);
  margin-bottom: 2rem;
}
