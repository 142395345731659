@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  background-color: #fafafa;
  background-color: red;
  // padding: 2rem 0rem 0rem 35rem;
}

.content {
  display: flex;
  flex: 1;
  background-color: white;
  height: 100%;
  width: 100%;
  padding: 1rem;
}

.title {
  font-size: 4rem;
}

.dashboardContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0rem 2rem;
  overflow-y: hidden;
}

.taxPayerItemWrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 4rem;
  margin-bottom: 1rem;
  background-color: white;
  flex: 1;
  width: 100%;
  border-radius: 1rem;
  flex-direction: row;
  cursor: pointer;
}

.listWrapper {
  margin-top: 2rem;
}

.taxpayerName {
  font-size: 2rem;
  font-weight: 700;
  color: rgb(111, 111, 111);
}

.taxPayerAddress {
  color: rgb(111, 111, 111);
}

.basicInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.topPart {
  display: grid;
  grid-template-columns: 3fr 3fr;
  width: 100%;
  @include media-max($sdc-screen-mobile-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
  }
}

.flexEnd {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  @include media-max($sdc-screen-mobile-landscape) {
    justify-content: flex-start;
  }
}

.inputWrapper {
  display: flex;
  width: 20em;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
