*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

input, textarea, button, select, a { 
  -webkit-tap-highlight-color:transparent !important;
}

html {
  height: 100%;
  font-size: 62.5%;

  @include respond(iphone13) {
    font-size: 65%;      //1rem = 9px, 9/16 = 56.25%
}
}

body {
  box-sizing: border-box;
  height: 100%;
  font-weight: 400;
  font-size: 1.7rem;
  font-family: "Lato", sans-serif;
  line-height: 1.6;
  background-color: #ffffff;
  color: #39444f;
}

.hide__scrollbar {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
input {
  font-family: "Lato", sans-serif;
  font-size: 2.7rem;
  background-color: #39444f;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}
// main text: #39444f
// lightgray: #aebac5
// blue-slider: #4054af
