@import "../../sass/variables.scss";
@import "../../sass/mixins.scss";

.title {
  font-size: 2.5rem;
  white-space: nowrap;
  @include media-max($sdc-screen-mobile-landscape) {
    font-size: 2.5rem;
  }
}

.leftWrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.xlsIcon {
  width: 2.5rem;
  margin-left: 0.5rem;
}

.content {
  display: flex;
  flex: 1;
  background-color: #fafafa;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 0rem 2rem;
  padding-bottom: 2rem;
  @include media-max($sdc-screen-mobile-landscape) {
    padding: 0rem 1rem;
    padding-bottom: 5rem;
  }
}

.fullWidth {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 1rem 0rem;
}

.rightWrap {
  display: flex;
}

.graphViewWrap {
  cursor: pointer;
  padding: 0.3rem;
  display: flex;
  border-radius: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.graphViewWrapSelected {
  cursor: pointer;
  padding: 0.3rem;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 2rem;
  background-color: white;
}

.dashboardList {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 2rem;
  @include media-max($sdc-screen-tablet-landscape) {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  @include media-max($sdc-screen-mobile-landscape) {
    grid-template-columns: repeat(1, 1fr) !important;
    grid-template-rows: auto;
    grid-gap: 0.5rem;
  }
  @include media-max($sdc-screen-mobile-portrait) {
    grid-template-columns: repeat(1, 1fr) !important;
    grid-template-rows: auto;
    grid-gap: 0.5rem;
  }
  @include media-max($sdc-screen-laptop-md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.graphCard {
  display: flex;
  background-color: white;
  position: relative;
  width: 100%;
  flex-direction: column;
  margin-top: 2rem;
  height: 100%;
  padding: 2rem 2rem;
  border-radius: 1rem;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ecebeb;
  min-height: 45rem;
  cursor: default;
  @include media-max($sdc-screen-mobile-landscape) {
    padding: 1rem;
  }
}

.graphCardCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.graphCardMobile {
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  margin-top: 4rem;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-width: 35rem;
}

.circle {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  border-radius: 100%;
}

.cirleUnactive {
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  border-radius: 100%;
  background-color: #cbc9c9;
}

.viewOption {
  color: #000033 !important;
  @include media-max($sdc-screen-mobile-landscape) {
    font-size: 1.7rem;
  }
}

.viewOptionSelected {
  color: #000033 !important;
  @include media-max($sdc-screen-mobile-landscape) {
    font-size: 1.7rem;
  }
}

.topPart {
  display: grid;
  grid-template-columns: 3fr 3fr;
  width: 100%;
  padding: 1rem 0rem;
  @include media-max($sdc-screen-mobile-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
  }
}

.graphTitle {
  font-weight: 900;
  // font-size: 1.5rem;
  @include media-max($sdc-screen-mobile-landscape) {
    font-size: 1.7rem;
  }
}

.graphTitleWrap {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem 0rem;
  justify-content: space-between;
}

.businessUnitWrap {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  cursor: pointer;
}

.dashboardTitle {
  font-size: 4rem;
}

.graphHeader {
  display: flex;
  overflow: scroll;
  width: 100%;
}

.graphWrap {
  overflow: hidden;
  display: flex;
  flex: 1;
  width: 100%;
  padding-top: 1rem;
}

.graphViewOptionsWrap {
  display: flex;
  background-color: #f1f7ff !important;
  border-radius: 2rem;
  padding: 0.3rem;
  margin-right: 1rem;

  @include media-max($sdc-screen-mobile-landscape) {
    margin-right: 0rem;
  }
}

.exportBtnWrap {
  display: flex;
  background-color: #f1f7ff !important;
  border-radius: 2rem;
  padding: 0.3rem;
}

.chevron {
  width: 1.5rem;
  margin-left: 0.1rem;
}

.exportBt {
  cursor: pointer;
  padding: 0.3rem;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 2rem;
  background-color: white;
}

.wrap1 {
  display: flex;
  border-radius: 1rem;
  padding: 0.6rem;
}
.dashboardContent {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  flex-direction: column;
}

.rangePicker {
  display: flex;
  cursor: pointer;
  @include media-max($sdc-screen-mobile-landscape) {
    flex: 1;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
  }
}

.selectedReportOption {
  white-space: nowrap;
  @include media-max($sdc-screen-mobile-landscape) {
    font-size: 1.7rem;
    width: 3.5rem;
  }
}

.reportOptionText {
  white-space: nowrap;
  @include media-max($sdc-screen-mobile-landscape) {
    font-size: 1.7rem;
  }
}

.optionsTooltip {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  right: 1rem;
  top: 7rem;
  border-radius: 1rem;
  -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  background-color: white;
}

.tooltipOption {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    -webkit-box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
    box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.11);
  }
}

.tooltipOptionTop {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.tooltipOptionBottom {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.warningBanner {
  display: flex;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  background-color: rgb(227, 81, 81);
  width: 96%;
  margin-left: 2%;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem 2rem;
  flex-direction: column;
  @include media-max($sdc-screen-mobile-landscape) {
    align-items: center;
  }
}

.warningText {
  color: white;
  font-size: 2rem;
  white-space: nowrap;
}

.warningMessage {
  color: white;
  font-size: 1.5rem;
  white-space: nowrap;
}
.warningMessagesWrap {
  display: flex;
  flex-direction: column;
}
