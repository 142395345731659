@import "../../../sass/variables.scss";
@import "../../../sass/mixins.scss";

.modalWrap {
  position: absolute;
  display: flex;
  width: 20%;
  height: 20%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 20rem;
  min-height: 20rem;
  outline: none;
  @include media-max($sdc-screen-tablet-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
    width: 30%;
    height: 24%;
  }
  @include media-max($sdc-screen-mobile-landscape) {
    grid-template-columns: none;
    grid-gap: rem(10px);
    width: 80%;
    height: 24%;
  }
}

.modalInner {
  display: flex;
  flex: 1;
  border-radius: 2rem;
  background-color: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  overflow: auto;
  position: relative;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%);
}

.cancel {
  cursor: pointer;
  text-decoration: underline;
}

.modalText {
  top: 5rem;
  margin-top: 3rem;
  font-size: 2rem;
}
